import React, { useState } from 'react';
import classes from './styles.module.css';
import { ReactComponent as GoogleIcon } from '../../images/social_google.svg';
import { ReactComponent as AppleIcon } from '../../images/social_apple.svg';
import ReactDOM from "react-dom";
import ModalSocialLink from "../Modals/ModalSocialLink";

const SocialLinks = ({ direction }) => {
    const [isOpenModalSocialLink, setIsOpenModalSocialLink] = useState(false);
    const modalSocialLinkConst = document.getElementById('portal');

    return (
        <>
            <ul className={direction === 'column' ? `${classes.socialLinks} ${classes.column}` : `${classes.socialLinks}`}>
                <li className={classes.socialLinksItem}>
                    {/*<a href='/' onClick={() => setIsOpenQuizModal(true)}><GoogleIcon /></a>*/}
                    <div onClick={() => setIsOpenModalSocialLink(true)}><GoogleIcon /></div>
                </li>
                <li className={classes.socialLinksItem}>
                    {/*<a href='/' onClick={() => setIsOpenQuizModal(true)}><AppleIcon /></a>*/}
                    <div onClick={() => setIsOpenModalSocialLink(true)}><AppleIcon /></div>
                </li>
            </ul>

            {modalSocialLinkConst && ReactDOM.createPortal(<ModalSocialLink isOpenModalSocialLink={isOpenModalSocialLink} setIsOpenModalSocialLink={setIsOpenModalSocialLink}/>, modalSocialLinkConst)}
        </>
    );
};

export default SocialLinks;
