import React, { useState, useRef } from 'react';
import classes from './styles.module.css'
import Container from '../Container';
import HowItWorksSlider  from './Slider';
import { sliderDataTourist, sliderDataGuide } from './Slider/sliderData';
import clsx from 'clsx';

const HowItWorks = ({ id, type }) => {
    const [choosenSlide, setChoosenSlide] = useState(0);
    const sliderRef = useRef(null);

    const handleClickSlide = (slideNumber) => () => {
        setChoosenSlide(slideNumber);
        sliderRef.current.slickGoTo(slideNumber);
    }

    return (
        <div id={id} className={classes.howItWorks}>
            <Container>
                <div className={classes.howItWorksContainer}>
                    <HowItWorksSlider
                        type={type}
                        setChoosenSlide={setChoosenSlide}
                        ref={sliderRef}
                    />
                    <div className={classes.rightText}>
                        <h2>How it works</h2>
                        <div className={classes.tabsBox}>
                            {type === 'tourist' &&
                                sliderDataTourist.map((slide, i) => {
                                    const active = i === choosenSlide;

                                    return (
                                        <div
                                            key={slide.title}
                                            className={active ? classes.cardActive : classes.card}
                                            onClick={handleClickSlide(i)}
                                        >
                                            <div
                                                className={active ? classes.titleActive : classes.title}
                                            >
                                                {slide.title}
                                            </div>
                                            <div>{slide.subtitle}</div>
                                        </div>
                                    );
                                })}

                            {type === 'guide' &&
                                sliderDataGuide.map((slide, i) => {
                                    const active = i === choosenSlide;

                                    return (
                                        <div
                                            key={slide.title}
                                            className={active ? classes.cardActive : classes.card}
                                            onClick={handleClickSlide(i)}
                                        >
                                            <div
                                                className={active ? classes.titleActiveGradient : classes.title}
                                            >
                                                {slide.title}
                                            </div>
                                            <div>{slide.subtitle}</div>
                                        </div>
                                    );
                                })}

                            <div className={classes.dotsBox}>
                                {sliderDataTourist.map((_, i) => {
                                    const active = i === choosenSlide;
                                    return (
                                        <div
                                            key={_.phoneImg}
                                            onClick={handleClickSlide(i)}
                                            className={clsx([
                                                classes.dotsLine,
                                                active && type === 'tourist' && classes.dotsLineActive,
                                                active && type === 'guide' && classes.dotsLineActiveGuide,
                                            ])}
                                        />
                                    );
                                })}
                            </div>

                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default HowItWorks;