export const menuListTourist = [
  { title: 'Why us', link: '#why-us' },
  { title: 'How it works', link: '#how-it-works' },
  { title: 'Our mission', link: '#our-mission' },
  { title: 'Become a guide', link: '#become-guide' },
  { title: 'What they say', link: '#what-they-say' },
];

export const menuListGuide = [
  { title: 'Benefits', link: '#why-us' },
  { title: 'How it works', link: '#how-it-works' },
  { title: 'Our mission', link: '#our-mission' },
  { title: 'Join us', link: '#guide-form' },
  { title: 'What they say', link: '#what-they-say' },
];