import Bg1 from '../../../images/hiw_bg_1.png';
import Bg2 from '../../../images/hiw_bg_2.png';
import Bg3 from '../../../images/hiw_bg_3.png';
import Bg4 from '../../../images/hiw_bg_4.png';
import TouristPhone1 from '../../../images/hiw_phone_1.png';
import TouristPhone2 from '../../../images/hiw_phone_2.png';
import TouristPhone3 from '../../../images/hiw_phone_3.png';
import TouristPhone4 from '../../../images/hiw_phone_4.png';
import GuidePhone1 from '../../../images/guide_hiw_phone_1.png';
import GuidePhone2 from '../../../images/guide_hiw_phone_2.png';
import GuidePhone3 from '../../../images/guide_hiw_phone_3.png';
import GuidePhone4 from '../../../images/guide_hiw_phone_4.png';

export const sliderDataTourist = [
    {
      phoneImg: TouristPhone1,
      bgImg: Bg1,
      title: 'Choose a perfect tour',
      subtitle: <>You can decide yourself what topic, distance and duration you prefer</>
    },
    {
      phoneImg: TouristPhone2,
      bgImg: Bg2,
      title: 'Go to the start point',
      subtitle: <>Our app will guide you to the start location where you can begin your adventure</>
    },
    {
      phoneImg: TouristPhone3,
      bgImg: Bg3,
      title: "Don't miss anything",
      subtitle: <>Our app will send you push notifications if you come nearby interesting places</>
    },
    {
      phoneImg: TouristPhone4,
      bgImg: Bg3,
      title: 'Pause at any time',
      subtitle: <>Need some rest? You can stop the tour whenever you want and then continue once you are ready</>
    }
  ];

  export const sliderDataGuide = [
    {
      phoneImg: GuidePhone1,
      bgImg: Bg2,
      title: 'Choose a topic',
      subtitle: <>Decide what you want to tell people about: Museum, Old City Legend, Best Restaurants in town, etc.</>
    },
    {
      phoneImg: GuidePhone2,
      bgImg: Bg4,
      title: 'Set up your Tour',
      subtitle: <>Add the core parameters of your tour, such as: Name, Description and Duration, etc.</>
    },
    {
      phoneImg: GuidePhone3,
      bgImg: Bg3,
      title: 'Create Tour',
      subtitle: <>You can create unique and complex tour. Divide it into chapters, build route on map and various helpful materials: Images, Audio, Video and Images.</>
    },
    {
      phoneImg: GuidePhone4,
      bgImg: Bg1,
      title: 'Publish Tour and Earn Income',
      subtitle: <>Once your tour is quality-checked by our team it will be available for sale and thousands of delightful reviews</>
    }
  ];