import React from 'react';
import classes from './styles.module.css'
import Container from '../Container';
import becomeGuideImage from '../../images/becomeGuide/become_guide_bg.png';
import leaderBoardImage from '../../images/becomeGuide/leaderBoard.png';
import firstBecomeGuide from '../../images/becomeGuide/first_become_guide.svg';
import secondBecomeGuide from '../../images/becomeGuide/second_become_guide.svg';
import thirdBecomeGuide from '../../images/becomeGuide/third_become_guide.svg';
import {ReactComponent as PlusIcon} from '../../images/plus.svg';
import useHash from '../../hooks/useHash';

const becomeGuideList = [
    {
        image: firstBecomeGuide,
        title: 'Become a part of something bigger'
    },
    {
        image: secondBecomeGuide,
        title: 'Tell your story to the world'
    },
    {
        image: thirdBecomeGuide,
        title: 'Get passive income'
    },
];

const BecomeGuide = ({ id }) => {
    useHash();

    return (
        <div id={id} className={classes.becomeGuide}>
            <Container>
                <div className={classes.becomeGuideContainer}>
                    <div className={classes.becomeGuideLeft}>
                        <img className={classes.becomeGuideImage} src={becomeGuideImage} alt='Become a guide' />
                        <img className={classes.leaderBoardImage} src={leaderBoardImage} alt='Leader board' />
                        <div className={classes.becomeGuideTab}>
                            <div className={classes.becomeGuideTabTitle}>First 100 guides</div>
                            <p>Receives special benefits</p>
                        </div>
                        <a className={classes.becomeGuideLeftButton} href={'/guide'}>
                            <PlusIcon className={classes.becomeGuideLeftButtonPlus} />
                        </a>
                    </div>
                    <div className={classes.becomeGuideRight}>
                        <div className={classes.preTitle}>Are you a local?</div>
                        <h2>Become <span>a guide</span></h2>
                        <div className={classes.becomeGuideText}>
                            You know the special places and stories in your city that make it so unique?
                            Then this is your chance to share it and join Umeguide. Why?
                        </div>
                        <ul className={classes.becomeGuideList}>
                            {becomeGuideList.map((item) => {
                                return (
                                    <li className={classes.becomeGuideItem} key={item.title}>
                                        <img className={classes.becomeGuideItemImage} src={item.image} alt={item.title} />
                                        <span className={classes.becomeGuideItemTitle}>{item.title}</span>
                                    </li>
                                )
                            })}
                        </ul>
                        <a className={classes.becomeGuideLearMoreButton} href={'/guide'}>
                            Learn more
                        </a>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default BecomeGuide;