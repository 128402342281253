import React from 'react';
import classes from './styles.module.css';
import Container from '../../components/Container';
import Logo from '../../components/Logo';
import SocialLinks from '../../components/SocialLinks';
import FooterColumn from './FooterColumn';

const footerColumnMenu = [
    {
        title: 'Company',
        menu: [
            { menuItem: 'About us', link: '#why-us' },
            { menuItem: 'Contacts', link: '' },
        ],
    },
    {
        title: 'For tourists',
        menu: [
            { menuItem: 'Popular tours in your city', link: '' },
            { menuItem: 'Popular tours in your country', link: '' },
            { menuItem: 'Random tour', link: '' },
        ],
    },
    {
        title: 'For guides',
        menu: [
            { menuItem: 'Become a guide', link: '/tourist#become-guide' },
            { menuItem: 'Calculate your income', link: '/guide#calculate-income' },
        ],
    },
    {
        title: 'Legal',
        menu: [
            { menuItem: 'Cookies Policy', link: '' },
            { menuItem: 'Privacy Policy', link: '' },
            { menuItem: 'Terms of Use', link: '' },
        ],
    },
];

const Footer = () => {
    return (
        <div className={classes.footer}>
            <Container direction='column'>
                <Logo />
                <div className={classes.footerInfo}>
                    <div className={classes.footerLeft}>
                        {footerColumnMenu.map((item) => {
                            return (
                                <FooterColumn className={classes.footerColumn} key={item.title} {...item} />
                            );
                        })}
                    </div>
                    <div className={classes.footerRight}>
                        <SocialLinks direction='column' />
                        <div className={classes.copyright}>© 2022 Umeguide.</div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Footer;