export const questionsList = [
  {
    questionText: 'Longest river in the world?',
    answerOptions: [
      { id: 1, answerText: 'Dunaj', isCorrect: false },
      { id: 2, answerText: 'Nile', isCorrect: true },
      { id: 3, answerText: 'Dnipro', isCorrect: false },
      { id: 4, answerText: 'Ganges', isCorrect: false },
    ],
  },
  {
    questionText: 'The capital of USA?',
    answerOptions: [
      { id: 1, answerText: 'Boston', isCorrect: false },
      { id: 2, answerText: 'Washington', isCorrect: true },
      { id: 3, answerText: 'New York', isCorrect: false },
      { id: 4, answerText: 'Los-Angeles', isCorrect: false },
    ],
  },
  {
    questionText: 'The most popular sportsman in the world?',
    answerOptions: [
      { id: 1, answerText: 'Lebron James', isCorrect: false },
      { id: 2, answerText: 'Lionel Messi', isCorrect: false },
      { id: 3, answerText: 'Cristiano Ronaldo', isCorrect: true },
      { id: 4, answerText: 'Conor McGregor', isCorrect: false },
    ],
  },
  {
    questionText: 'What does not apply to Marvel?',
    answerOptions: [
      { id: 1, answerText: 'Batman', isCorrect: true },
      { id: 2, answerText: 'Spider Man', isCorrect: false },
      { id: 3, answerText: 'Wolverine', isCorrect: false },
      { id: 4, answerText: 'Iron Man', isCorrect: false },
    ],
  },
  {
    questionText: 'What is the largest city in the world by area?',
    answerOptions: [
      { id: 1, answerText: 'New York', isCorrect: false },
      { id: 2, answerText: 'Shanghai', isCorrect: false },
      { id: 3, answerText: 'Tokio', isCorrect: true },
      { id: 4, answerText: 'Beijing', isCorrect: false },
    ],
  },
];