import React, { useState } from 'react';
import classes from './styles.module.css'
import SocialLinks from '../SocialLinks';
import bannerAdaptiveImage from '../../images/discover_banner_adaptive.png';
import { ReactComponent as ArrowIcon } from '../../images/arrow.svg';
import ModalQuiz from '../Modals/ModalQuiz';
import ReactDOM from 'react-dom';

const Discover = () => {
    const [isOpenQuizModal, setIsOpenQuizModal] = useState(false);
    const portalPlace = document.getElementById('portal');

    return (
        <>
            <div className={classes.discover}>
                <div className={classes.discoverContainer}>
                    <div className={classes.content}>
                        <h2>Discover the world with Umeguide</h2>
                        <div className={classes.description}>
                            Choose from a variety of self-guided tours of different themes
                            and places to make your average trip special - all over the planet.
                        </div>
                        <div className={classes.descriptionAdaptive}>
                            Are you tired of boring trips?
                            Download our app and discover
                            exciting opportunities!
                        </div>
                        <SocialLinks />
                        <div className={classes.quizCard} onClick={() => setIsOpenQuizModal(true)}>
                            <h5>Pssssss....</h5>
                            <div className={classes.quizCardText}>
                                Take a short quiz and get
                                a 25% discount on a tour
                                of your choice
                            </div>
                            <div className={classes.quizButton}>Take quiz <ArrowIcon /></div>
                        </div>
                    </div>
                    <div className={classes.banner}>
                        <img className={classes.bannerImage} src={bannerAdaptiveImage} alt='Banner' />
                    </div>
                    <h2 className={classes.adaptiveTitle}><span>Open the World&nbsp;</span>with our App</h2>
                </div>
            </div>

            {portalPlace && ReactDOM.createPortal(<ModalQuiz isOpenQuizModal={isOpenQuizModal} setIsOpenQuizModal={setIsOpenQuizModal}/>, portalPlace)}
        </>
    );
};

export default Discover;