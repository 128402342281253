import {useEffect} from 'react';
import {useLocation} from './useLocation';

const useHash = () => {
    const { hash } = useLocation();

    useEffect(() => {
        if (hash) {
            document.querySelector(hash).scrollIntoView({
                behavior: 'smooth'
            })
        }
    }, [hash]);

    return null;
};

export default useHash;