import React from 'react';
import VideoBanner from '../../components/VideoBanner';
import Benefits from '../../components/Benefits';
import Mission from '../../components/Mission';
import HowItWorks from '../../components/HowItWorks';
import CalculateIncome from '../../components/CalculateIncome';
import GuideForm from '../../components/FormComponents/GuideForm';
import WhatTheySay from '../../components/WhatTheySay';
import FAQ from '../../components/FAQ';

const Guide = () => {
    return (
        <>
            <VideoBanner />
            <Benefits
                id='why-us'
                type='guide'
                title={<>Why you want to create tours with <span>Umeguide</span></>}
            />
            <Mission id='our-mission' type='guide' />
            <HowItWorks id='how-it-works' type='guide' />
            <CalculateIncome id='calculate-income' />
            <GuideForm id='guide-form' />
            <WhatTheySay id='what-they-say' />
            <FAQ />
        </>
    );
};

export default Guide;