import React, {useEffect, useState} from 'react';
import classes from '../styles.module.css';
import { ReactComponent as CloseIcon } from '../../../images/close.svg';

const ModalError = ({ isOpenErrorModal, setIsOpenErrorModal, errorText }) => {
    const [isBlindColors, setIsBlindColors] = useState(false);

    const toggleErrorModal = () => {
        setIsOpenErrorModal(false)
    };

    useEffect(() => {
        const mainFilter = document.querySelector('main').style.filter;

        if (isOpenErrorModal) {
            document.body.style.overflow = 'hidden';
            if (mainFilter && mainFilter !== 'none') {
                setIsBlindColors(true);
            }
        } else {
            document.body.style.overflow = 'auto';
            setIsBlindColors(false);
        }
        return () => setIsBlindColors(false)
    }, [isOpenErrorModal]);

    return (
        <>
            {isOpenErrorModal
                && <div className={classes.overlay}>
                    <div className={isBlindColors ? `${classes.modal} ${classes.error} ${classes.blind}` : `${classes.modal} ${classes.error}`}>
                        <CloseIcon className={`${classes.modalClose} ${classes.error}`} onClick={toggleErrorModal} />
                        <h2>Error</h2>
                        <p className={classes.errorText}>{errorText}</p>
                    </div>
                    <div className={classes.modalBackdrop} onClick={toggleErrorModal} />
                </div>
            }
        </>
    );
};

export default ModalError;