export const faqList = [
    {
        title: 'What is meant by the various tour lists? Are these tours selected based on the profile?',
        description:
            <>
                This means that there are primarily different categories / lists (e.g. museum tours, city tours, hiking tours, night tours, etc.).
                The ranking within the categories results from the ranking (ratings) of the users. Accordingly, the ranked tours are displayed to the user,
                an algorithm that precisely matches tours to the behavior of the user is not necessary for the time being. However, it would subsequently be
                possible to increasingly suggest tours in a category preferred by the user (depending on how complex that is).
            </>
    },
    {
        title: 'Are the tours digital or with people leading the tour? ',
        description:
            <>
                The tours are completely digital and they are recorded by the guides themselves. They are accessed virtually via the smartphone, regardless of time and location.
            </>
    },
    {
        title: 'Which devices can I use for creating a tour?',
        description:
            <>
                <p>In a first step the "Umeguide for creators" app on your Iphone or Android smartphone will allow you to create the desired tours.
                    In a later step, it will also be possible to create tours through the web browser once such an area is published for the guide.
                    The tourist as well as the guide account pages will be accessible through the log-in on the current landing page.
                </p>
                <p>However, you can still edit content such as pictures or audio on your computer first, send it to your phone and then implement it in your tour.</p>
            </>
    },
    {
        title: 'What options do I have to create a tour? ',
        description:
            <>
                Our toolbox is all about allowing you to create a tour that is as individual and unique as possible. To this end you can enter text, upload images,
                insert audio material, add tipps and hints or create short multiple choice quizzes. It is advisable to take a look at the best practice in order
                to get a good understanding of how to get and hold your users attention and create a great experience.
            </>
    },
    {
        title: 'Do I need to allow the geo-location access for the app?',
        description:
            <>
                This feature is mostly important for tourists in order to get the full experience of the tour. But also as a guide, you can profit from this feature
                as it will allow you to use your current location as waypoints for the tours that you want to create. However, you can also choose your location
                manually when creating the tour.
            </>
    },
    {
        title: 'Should I be physically present at my tours and accompany travellers?',
        description:
            <>
                No, you shouldn’t:) Once you create a “self-guided” digital tour, it can be purchased and accessed by travellers through Umeguide App,
                where people can enjoy your masterpiece. But we of course recommend you to timely renew or improve your tour to guarantee the best experience for tourists.
            </>
    },
    {
        title: 'Can I amend my tour once it is published?',
        description:
            <>
                Yes, you can and are encouraged to change and improve your tour to a certain extent for the optimal user experience.
            </>
    },
    {
        title: 'Can I build a specific route between chapters of my tour to ensure a better experience for travellers?',
        description:
            <>
                You certainly can. The route-building tool gives you a lot of options to establish or rearrange your route in order for you to connect
                your story with the according route the best possible way.
            </>
    },
    {
        title: 'What do I need to become a part of Umeguide?',
        description:
            <>
                If you want to get creative and be part of our guide community, it’s really simple. Just create an account and provide us with
                some information about yourself,  your personal data will be secure and be limited to the necessary minimum. There is no special
                requirement or education needed to become part of the community but remember - there are a lot of great stories to be told and
                places to be seen out there, so make sure to learn some basics about how to create an attractive tour to get your tours the sales they deserve.
            </>
    },
    {
        title: 'I’m not a professional guide and don’t have experience in creating self-guided tours. Can I still become a creator at Umeguide?',
        description:
            <>
                Although professional guides might have more experience in this regard, we at Umeguide believe that every person can share his or
                her story about places they love. Whether a blogger, a writer or just a local enthusiast - with passion and creativity everyone
                can create something unique.
            </>
    },
    {
        title: 'Which financial aspects do I have to consider when selling tours on Umeguide?',
        description:
            <>
                Umeguide is a platform which does not create content but supports you in selling your tours. Therefore, in most countries,
                this constitutes (self-employed) personal income which is taxed on a personal level. For more information about the tax laws
                in your country, please consult with your local tax adviser.
            </>
    },
    {
        title: 'Can I receive help from you in creating tours?',
        description:
            <>
                Everybody has to start somewhere and needs to collect experiences, make mistakes and learn from them. In order for you to speed up the
                learning curve, we will publish some tipps and tricks, tutorials and macro-statistics which will help you to better
                understand the mechanics and structuring of the tour creation.
            </>
    },
];