import React from 'react';
import classes from './styles.module.css';
import Container from '../Container';
import missionImage from '../../images/mission_image.png';
import { missionListTourist, missionListGuide } from './missionList';

const Mission = ({ id, type }) => {
    return (
        <div id={id} className={classes.mission}>
            <Container>
                <div className={classes.missionContainer}>
                    {type === 'guide'
                        ? missionListGuide.map((item) => {
                            return (
                                <div className={classes.missionContent} key={item.title}>
                                    <h2>{item.title}</h2>
                                    <div className={classes.missionText}>
                                        {item.text}
                                    </div>
                                </div>
                            )
                        })
                        : missionListTourist.map((item) => {
                            return (
                                <div className={classes.missionContent} key={item.title}>
                                    <h2>{item.title}</h2>
                                    <div className={classes.missionText}>
                                        {item.text}
                                    </div>
                                </div>
                            )
                        })
                    }
                    <img className={classes.missionImage} src={missionImage} alt='Mission' />
                </div>
            </Container>
        </div>
    );
};

export default Mission;