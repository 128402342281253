import { useState, useEffect } from 'react'

function getCurrentLocation () {
    return {
        pathname: window.location.pathname,
        search: window.location.search,
        hash: window.location.hash
    }
}

const listeners = []

export function notify () {
    listeners.forEach(listener => listener())
}

export function useLocation () {
    const [{ pathname, search, hash }, setLocation] = useState(getCurrentLocation())

    useEffect(() => {
        window.addEventListener('popstate', handleChange)
        return () => window.removeEventListener('popstate', handleChange)
    }, [])

    useEffect(() => {
        listeners.push(handleChange)
        return () => listeners.splice(listeners.indexOf(handleChange), 1)
    }, [])

    function handleChange () {
        setLocation(getCurrentLocation())
    }

    function push (url) {
        window.history.pushState(null, null, url)
        notify()
    }

    function replace (url) {
        window.history.replaceState(null, null, url)
        notify()
    }

    return {
        push,
        replace,
        pathname,
        search,
        hash
    }
}