import React from 'react';
import Discover from '../../components/Discover';
import Benefits from '../../components/Benefits';
import HowItWorks from '../../components/HowItWorks';
import Mission from '../../components/Mission';
import WhoWeAre from '../../components/WhoWeAre';
import JoinUs from '../../components/JoinUs';
import BecomeGuide from '../../components/BecomeGuide';
import WhatTheySay from '../../components/WhatTheySay';
import SubscribeForm from '../../components/FormComponents/SubscribeForm';

const Tourist = () => {
    return (
        <>
            <Discover />
            <Benefits
                id='why-us'
                title={<>Why use <span>Umeguide</span></>}
                subtitle='Experience a new level of exploring the places around you.'
            />
            <HowItWorks id='how-it-works' type='tourist' />
            <Mission id='our-mission' />
            <WhoWeAre />
            <JoinUs />
            <BecomeGuide id='become-guide' />
            <WhatTheySay id='what-they-say' />
            <SubscribeForm id='subscribe' />
        </>
    );
};

export default Tourist;