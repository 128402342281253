import React from 'react';
import classes from './styles.module.css';
import Container from '../../components/Container';
import Logo from '../../components/Logo';
import Menu from '../../components/Menu';
import HeaderButtons from '../../components/HeaderButtons';
import AdaptiveHeader from '../../components/AdaptiveHeader';

const Header = () => {
    return (
        <div className={classes.header}>
            <Container>
                <Logo variant='header' />
                <div className={classes.headerInfo}>
                    <Menu />
                    <HeaderButtons />
                </div>
                <AdaptiveHeader />
            </Container>
        </div>
    );
};

export default Header;