import React, {useEffect, useState} from 'react';
import classes from '../styles.module.css';
import { ReactComponent as CloseIcon } from '../../../images/close.svg';

const ModalSuccess = ({ isOpenSuccessModal, setIsOpenSuccessModal }) => {
    const [isBlindColors, setIsBlindColors] = useState(false);

    const toggleSuccessModal = () => {
        setIsOpenSuccessModal(false)
    };

    useEffect(() => {
        const mainFilter = document.querySelector('main').style.filter;

        if (isOpenSuccessModal) {
            document.body.style.overflow = 'hidden';
            if (mainFilter && mainFilter !== 'none') {
                setIsBlindColors(true);
            }
        } else {
            document.body.style.overflow = 'auto';
            setIsBlindColors(false);
        }
        return () => setIsBlindColors(false)
    }, [isOpenSuccessModal]);

    return (
        <>
            {isOpenSuccessModal
                && <div className={classes.overlay}>
                    <div className={isBlindColors ? `${classes.modal} ${classes.success} ${classes.blind}` : `${classes.modal} ${classes.success}`}>
                        <CloseIcon className={`${classes.modalClose} ${classes.success}`} onClick={toggleSuccessModal} />
                        <h2>Success!</h2>
                    </div>
                    <div className={classes.modalBackdrop} onClick={toggleSuccessModal} />
                </div>
            }
        </>
    );
};

export default ModalSuccess;