import firstBenefit from "../../images/benefits/first_benefit.svg";
import secondBenefit from "../../images/benefits/second_benefit.svg";
import thirdBenefit from "../../images/benefits/third_benefit.svg";
import fourthBenefit from "../../images/benefits/fourth_benefit.svg";
import fifthBenefit from "../../images/benefits/fifth_benefit.svg";
import sixthBenefit from "../../images/benefits/sixth_benefit.svg";
import seventhBenefit from "../../images/benefits/seventh_benefit.svg";
import eighthBenefit from "../../images/benefits/eighth_benefit.svg";

export const benefitsListTourist = [
  {
    image: firstBenefit,
    title: 'No need to stand in lines',
    text: 'Easily pay for the tour via app and start straight away.'
  },
  {
    image: secondBenefit,
    title: 'Cheaper prices',
    text: 'Our digital tours allow you to get the same real life experience for way less money.'
  },
  {
    image: thirdBenefit,
    title: 'Start your tour any time',
    text: 'Early bird or night owl - at Umeguide we have something for everyone.'
  },
  {
    image: fourthBenefit,
    title: 'Choose from a huge selection of tours that fits any taste',
    text: 'Classic sightseeing, countryside adventure or nightlife tour - you’ll find your perfect fit.'
  },
  {
    image: fifthBenefit,
    title: 'Not dependent on other tourists in the group',
    text: 'Pause the tour any time and relax for a while or have a snack at any time.'
  },
];

export const benefitsListGuide = [
  {
    image: sixthBenefit,
    title: 'Start right away',
    text: 'Your smartphone is all you need. Download the app and you are all set!'
  },
  {
    image: seventhBenefit,
    title: 'Create tour with no effort',
    text: 'Our intuitive tour constructor will guide you step-by-step.'
  },
  {
    image: eighthBenefit,
    title: 'Earn money remotely',
    text: 'Once your tour is online, your sales generate passive income.'
  },
];