import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import classes from './styles.module.css';
import { sliderDataTourist, sliderDataGuide } from './sliderData';

const HowItWorksSlider = React.forwardRef(({ type, setChoosenSlide }, ref) => {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        swipe: false,
        speed: 700,
        arrows: false,
        beforeChange: (current, next) => setChoosenSlide(next),
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
          {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                fade: false,
                swipe: true,
            }
          }
        ]
      }
  
  return (
      <Slider className={classes.slider} ref={ref} {...settings}>
          {type === 'tourist' && sliderDataTourist.map((slide) => (
              <div className={classes.card} key={slide.title}>
                  <img className={classes.bg} src={slide.bgImg} alt={slide.title} />
                  <img className={classes.phone} src={slide.phoneImg} alt={slide.title} />
              </div>

          ))}

          {type === 'guide' && sliderDataGuide.map((slide) => (
              <div className={classes.card} key={slide.title}>
                  <img className={classes.bg} src={slide.bgImg} alt={slide.title} />
                  <img className={classes.phone} src={slide.phoneImg} alt={slide.title} />
              </div>

          ))}
      </Slider>
  );
});

export default HowItWorksSlider;