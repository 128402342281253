import React, {useState} from 'react';
import classes from './styles.module.css'
import Container from '../Container';
/*import firstPerson from '../../images/whoWeAre/firstPerson.png';
import secondPerson from '../../images/whoWeAre/secondPerson.png';*/

const WhoWeAre = () => {
    const [state, setState] = useState(true);

    const handleClick = () => {
        setState(prev => !prev);
    };

    return (
        <div className={classes.whoWeAre}>
            <div className={classes.whoWeAreTop}>
                <h2 className={classes.whoWeAreTitle}>Who we are</h2>
            </div>
            <Container>
                <div className={classes.whoWeAreList}>
                    <div className={classes.whoWeArePerson}>
                        <div className={classes.whoWeArePersonImage}>
                            {/*<img src={firstPerson} alt='Dmitriy Garmash' />*/}
                        </div>
                        <div className={classes.whoWeArePersonTitle}>Dmitriy</div>
                        <div className={classes.whoWeArePersonPosition}>CEO at Umeguide</div>
                    </div>
                    <div className={classes.whoWeAreContent}>
                        <p>
                            Even as students, one thing always bothered us when traveling: You always have to do research beforehand in order not to walk
                            into tourist traps and to find out the best places and experiences. Travel blogs often offer good insights, but only from one
                            person and are not very individual. Personal tour guides usually use the one-fits-all strategy and can hardly take niche needs into account.
                        </p>
                        <button
                            type='button'
                            className={state ? `${classes.showMoreButton}` : `${classes.showMoreButtonHidden}`}
                            onClick={handleClick}
                        >
                            Show more
                        </button>
                        <p className={state ? `${classes.hiddenContent}` : `${classes.hiddenContentOpen}`}>
                            This is exactly where Umeguide comes in. We decided to help hundreds locals with different interests pass on their knowledge to you.
                            This puts an end to lengthy research and expensive tours. Just travel, choose a tour according to your preferences and off you go!
                            And who knows, maybe one day you decide to create your own tour and share some great experiences with happy travellers.
                        </p>
                    </div>
                    <div className={classes.whoWeArePerson}>
                        <div className={`${classes.whoWeArePersonImage} ${classes.second}`}>
                            {/*<img src={secondPerson} alt='Andi Secondname' />*/}
                        </div>
                        <div className={classes.whoWeArePersonTitle}>Andi</div>
                        <div className={classes.whoWeArePersonPosition}>CEO at Umeguide</div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default WhoWeAre;