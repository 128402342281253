import React from 'react';
import classes from './styles.module.css'
import Container from '../Container';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import joinUsImage from '../../images/join_us_bg.png';
import { ReactComponent as PlusIcon } from '../../images/plus.svg';
import Item from "./Item";

const joinUsList = [
    { number: '288', interval: 15, description: 'Tours around the world' },
    { number: '46', interval: 50, description: 'Professional guides' },
    { number: '78', interval: 40, description: 'Cities visited' },
    { number: '1252', interval: 2, description: 'Km covered on our tours' },
];

const JoinUs = () => {
    return (
        <div className={classes.joinUs}>
            <Container>
                <div className={classes.joinUsContainer}>
                    <div>
                        <h2>Mission, Vision and <span>Values</span></h2>
                        <div className={classes.joinUsList}>
                            {joinUsList.map((item) => {
                                return (
                                    <li className={classes.joinUsItem} key={item.number}>
                                        <Item item={item} />
                                        <span>{item.description}</span>
                                    </li>
                                )
                            })}
                        </div>
                    </div>
                    <div className={classes.joinUsRight}>
                        <div className={classes.subtitle}>Join us</div>
                        <AnchorLink type='button' className={classes.joinUsButton} href='#subscribe'><PlusIcon className={classes.joinUsButtonPlus} /></AnchorLink>
                        <img className={classes.joinUsImage} src={joinUsImage} alt='Join us' />
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default JoinUs;