import React, { useState } from 'react';
import classes from './../styles.module.css';
import AnchorLink from 'react-anchor-link-smooth-scroll/lib/anchor-link';
import { ReactComponent as PlusIcon } from '../../../images/plus.svg';
import { ReactComponent as MinusIcon } from '../../../images/minus.svg';

const FooterColumn = ({ title, menu }) => {
    const [state, setState] = useState(true);

    const handleClick = () => {
        setState(prev => !prev);
    };

    return (
        <div className={classes.footerColumn}>
            <h6 className={classes.footerColumnTitle} onClick={handleClick}>
                {title}
                {state ? <PlusIcon className={classes.footerToggleIcon} /> : <MinusIcon className={classes.footerToggleIcon} />}
            </h6>
            <ul className={state ? `${classes.footerColumnList}` : `${classes.footerColumnListOpen}`}>
                {menu.map((item) => {
                    const Link = item.link.startsWith('#') ? AnchorLink : 'a';

                    return (
                        <div className={classes.footerColumnItem} key={item.menuItem}>
                            <Link href={item.link}>{item.menuItem}</Link>
                        </div>
                    );
                })}
            </ul>
        </div>
    );
};

export default FooterColumn;