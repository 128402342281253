import React, {useState} from 'react';
import classes from './styles.module.css'
import Container from '../Container';
import { ReactComponent as BenefitsImage } from '../../images/benefits/benefits_image.svg';
import useMediaQuery from '../../hooks/useMediaQuery';
import { benefitsListTourist, benefitsListGuide } from './benefitsList';
import { useLocation } from '../../hooks/useLocation';

const Benefits = ({ id, type, title, subtitle }) => {
    const [showMore, setShowMore] = useState(false);
    const isDesktop = useMediaQuery('(min-width: 769px)');
    const { pathname } = useLocation();

    const handleShowMoreButton = () => {
        setShowMore(true)
    }

    return (
        <div id={id} className={classes.benefits}>
            <Container direction='column'>
                <div className={classes.benefitsTop}>
                    <div>
                        <div className={classes.preTitle}>Benefits</div>
                        <h2>{title}</h2>
                        <div className={classes.subTitle}>
                            {subtitle}
                        </div>
                    </div>
                    {pathname === '/guide' ? '' : <BenefitsImage className={classes.benefitsImage} />}
                </div>
                <ul className={classes.benefitsList}>
                    {type === 'guide'
                        ? benefitsListGuide.slice(0, isDesktop || showMore ? benefitsListGuide.length : 3).map((item) => {
                            return (
                                <li className={classes.benefitsItem} key={item.title}>
                                    <img className={classes.benefitsItemImage} src={item.image} alt={item.title} />
                                    <div className={classes.benefitsItemTitle}>{item.title}</div>
                                    <div className={classes.benefitsItemText}>{item.text}</div>
                                </li>
                            )
                        })
                        : benefitsListTourist.slice(0, isDesktop || showMore ? benefitsListTourist.length : 3).map((item) => {
                            return (
                                <li className={classes.benefitsItem} key={item.title}>
                                    <img className={classes.benefitsItemImage} src={item.image} alt={item.title} />
                                    <div className={classes.benefitsItemTitle}>{item.title}</div>
                                    <div className={classes.benefitsItemText}>{item.text}</div>
                                </li>
                            )
                        })
                    }
                    {!isDesktop && !showMore && pathname !== '/guide' ? <button className={classes.showMoreButton} onClick={handleShowMoreButton}>Show more</button> : null}
                </ul>
            </Container>
        </div>
    );
};

export default Benefits;