import React from 'react';
import classes from './styles.module.css';
import { ReactComponent as LogoIcon } from '../../images/logo.svg';

const Logo = ({ variant }) => {
    return (
        <a href='/' className={variant === 'header' ? `${classes.logo} ${classes.header}` : `${classes.logo}`}>
            <LogoIcon />
            <span>Umeguide</span>
        </a>
    );
};

export default Logo;