export const missionListTourist = [
    {
      title: <>Mission, Vision and <span>Values</span></>,
      text:
          <>
            <p>
              Telling stories is as old as humanity itself. It brings people together and allows them to learn from each other as well
              as finding new perspectives about the things they are already familiar with.
            </p>
            <p>
              Umeguide allows tourists to explore their travel destination with maximum individuality by offering a broad spectrum of
              tours in your area from people like you and me.
            </p>
          </>
    },
];

export const missionListGuide = [
  {
    title: <>Our <span>Vision</span></>,
    text:
        <>
          <p>
            Our world is full of secrets and great adventures. With Umeguide, these experiences are finally available to all the curious minds out there.
            The ongoing and unstoppable digitalisation of various aspects of our environment presents a great chance.
          </p>
          <p>
            We seek to utilize this trend so people go out and learn and travel more to enrich their lives, rather than spending their days at home on the sofa.
            Through our self-guided tours, Umeguide promotes finding out more about the tourist’s destination in an entertaining way and ensures the creators
            due compensation for his or her effort.
          </p>
        </>
  },
];