import React, {useEffect, useState} from 'react';
import classes from './styles.module.css';
import Menu from '../Menu';
import { ReactComponent as EyeIcon } from '../../images/eye.svg'
import { ReactComponent as BurgerIcon } from '../../images/menu.svg';

const AdaptiveHeader = () => {
    const [open, setOpen] = useState(false);
    const [openTheme, setOpenTheme] = useState(false);

    const toggleOpen = () => {
        setOpen(prev => !prev)
    };

    const toggleTheme = () => {
        setOpenTheme(!openTheme)
    }

    useEffect(() => {
        if (openTheme) {
            document.querySelector('main').style.filter = 'grayscale(90%)';
        } else {
            document.querySelector('main').style.filter = 'none';
        }
    }, [openTheme]);

    return (
        <div className={classes.adaptiveHeader}>
            {open && <div className={classes.overlay} onClick={toggleOpen} />}
            <div className={classes.adaptiveHeaderButton} onClick={toggleTheme}>
                <EyeIcon />
            </div>
            <div className={classes.adaptiveHeaderButton}>
                <BurgerIcon onClick={toggleOpen} />
            </div>
            {open && <div className={classes.dropdownMenu}>
                <Menu variant='adaptive' />
            </div>}
        </div>
    );
};

export default AdaptiveHeader;