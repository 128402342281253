import React, { useState } from 'react';
import classes from './styles.module.css';
import Container from '../Container';
import Range from './Range';
import clsx from 'clsx';
import useHash from '../../hooks/useHash';

const CalculateIncome = ({ id }) => {
    const [salesValue, setSalesValue] = useState({values: [1000]});
    const [publishedValue, setPublishedValue] = useState({values: [15]});
    const [priceValue, setPriceValue] = useState({values: [15]});

    const estimatedAnnualIncome = Math.round(publishedValue.values[0] * salesValue.values[0] * priceValue.values[0]).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const monthlyIncome = Math.round((publishedValue.values[0] * salesValue.values[0] * priceValue.values[0]) / 12).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    useHash();

    return (
        <div id={id} className={classes.calculateIncome}>
            <Container direction='column'>
                <h2>
                    Calculate your <span>Income</span>
                </h2>
                <div className={classes.rangeRow}>
                    <Range
                        step={1}
                        min={1}
                        max={2000}
                        value={salesValue}
                        setValue={setSalesValue}
                        label='Sales per tour'
                    />
                    <Range
                        step={1}
                        min={1}
                        max={30}
                        value={publishedValue}
                        setValue={setPublishedValue}
                        label='Published tours'
                    />
                    <Range
                        step={0.5}
                        min={0.5}
                        max={30}
                        value={priceValue}
                        setValue={setPriceValue}
                        label='Price'
                        price
                    />
                </div>
                <div className={classes.resultBox}>
                    <div className={clsx(classes.box, classes.estimatedBox)}>
                        <div className={classes.estimatedTitle}>Estimated Annual Income:</div>
                        <div className={classes.estimatedPrice}>
                            ${estimatedAnnualIncome}
                        </div>
                    </div>

                    <div className={clsx(classes.box, classes.monthlyBox)}>
                        <div className={classes.monthlyTitle}>Monthly Income:</div>
                        <div className={classes.monthlyPrice}>
                            ${monthlyIncome}
                        </div>
                    </div>
                </div>
                <div className={classes.afterCalculateText}>
                    All numbers exclusively serve the purpose of demonstrating possible income scenarios and are no guarantee for minimum sales prices,
                    number of sales or any other conditions that may be implied.
                </div>
            </Container>
        </div>
    );
};

export default CalculateIncome;
