import React from 'react';
import classes from './styles.module.css';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { menuListGuide, menuListTourist } from './menuList';
import { useLocation } from '../../hooks/useLocation';

const Menu = ({ variant }) => {
    const { pathname } = useLocation();

    return (
        <ul className={variant === 'adaptive' ? `${classes.adaptiveMenu}` : `${classes.menu}`}>
            {pathname === '/guide'
                ? menuListGuide.map((item) => {
                    const Link = item.link.startsWith('#') ? AnchorLink : 'a';

                    return (
                        <li className={classes.menuItem} key={item.title}>
                            <Link className={classes.menuItemLink} href={item.link}>{item.title}</Link>
                        </li>
                    );
                })
                : menuListTourist.map((item) => {
                    const Link = item.link.startsWith('#') ? AnchorLink : 'a';

                    return (
                        <li className={classes.menuItem} key={item.title}>
                            <Link className={classes.menuItemLink} href={item.link}>{item.title}</Link>
                        </li>
                    );
                })
            }
        </ul>
    );
};

export default Menu;
