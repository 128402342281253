import React, { useState } from 'react';
import classes from './styles.module.css'
import Container from '../Container';
import { ReactComponent as VideoButton } from '../../images/video_button.svg';

const VideoBanner = () => {
    const [state, setState] = useState(false);

    const startVideo = () => {
        setState(true);
    }

    return (
        <div className={classes.videoBanner}>
            <Container>
                <div className={classes.videoContainer}>
                    {state !== true &&
                        <div className={classes.poster} onClick={startVideo}>
                            <button type='button' className={classes.posterButton}>
                                <VideoButton />
                            </button>
                            <div className={classes.posterText}>
                                <div className={classes.posterTitle}>What is Umeguide?</div>
                                <div className={classes.posterDescription}>
                                    Do you want to become a guide?
                                    Than this is your chance!
                                </div>
                            </div>
                        </div>
                    }
                    <iframe width='100%' height='100%' src='https://www.youtube.com/embed/bSFA6jT1zgo' title='YouTube video player'
                            frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                            allowFullScreen
                            className={classes.frame}
                    />
                </div>
            </Container>
        </div>
    );
};

export default VideoBanner;