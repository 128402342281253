import { gql } from '@apollo/client';

export const subscribeMutationGql = gql`
    mutation subscribe($type: SubscriptionTypes!, $email: String!, $fullName: String) {
        subscribe(type: $type, email: $email, fullName: $fullName) {
            id
            type
            email
            fullName
            createdAt
        }
    }
`