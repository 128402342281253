import Layout from './layout';

// trigger website changes

function App() {
  return (
      <Layout />
  );
}

export default App;
