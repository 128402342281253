import React, {useCallback, useEffect, useMemo, useState} from 'react';
import classes from '../styles.module.css';
import { ReactComponent as CloseIcon } from '../../../images/close.svg';
import { questionsList } from './questionsList';
import useForm from '../../../hooks/useForm';
import { validateEmail } from '../../FormComponents/formValidationRules';
import { useMutation } from '@apollo/client';
import { subscribeMutationGql } from '../../../graphql/mutations.gql';
import ModalSuccess from '../ModalSuccess';
import ModalError from '../ModalError';
import ReactDOM from 'react-dom';

const ModalQuiz = ({ isOpenQuizModal, setIsOpenQuizModal }) => {
    const [subscribeFunction] = useMutation(subscribeMutationGql);
    const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);
    const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
    const [errorText, setErrorText] = useState('');
    const portalPlace = document.getElementById('portal');

    const toggleModal = () => {
        setIsOpenQuizModal(false)
        setSelected(undefined)
    };

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [showScore, setShowScore] = useState(0);
    const [selected, setSelected] = useState();
    const [error, setError] = useState(false);

    const [isBlindColors, setIsBlindColors] = useState(false);

    const handleAnswerClick = (item) => () => {
        setSelected(item)
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        handleReset,
    } = useForm(handleSubscribe, validateEmail);

    function handleSubscribe() {
        subscribeFunction({
            variables: {
                type: 'Tourist',
                email: values.email,
            }
        }).then(response => {
            if (response?.data?.subscribe?.id) {
                toggleModal()
                setIsOpenSuccessModal(true)
                handleReset()
            }
            if (response?.errors?.[0]?.message) {
                toggleModal()
                setErrorText(response?.errors?.[0]?.message)
                setIsOpenErrorModal(true)
            }
        }).catch((error) => {
            if (error?.message) {
                setErrorText(error?.message)
                setIsOpenErrorModal(true)
            }
        })
    }

    const getClassName = (item) => {
        if (selected?.id === item.id) {
            if (error) {
                return `${classes.answerItemButton} ${item?.isCorrect ? classes.success : classes.error}`
            }
            return `${classes.answerItemButton} ${classes.checked}`
        }
        if (error && item.isCorrect) {
            return `${classes.answerItemButton} ${classes.success}`
        }
        return classes.answerItemButton
    }

    const handleNextQuestion = useCallback(() => {
        const nextQuestion = currentQuestion + 1;

        if (nextQuestion < questionsList.length) {
            setCurrentQuestion(prevState => prevState + 1)
            setSelected(undefined)
        } else {
            setShowScore(true)
        }

        setError(false)
    }, [currentQuestion]);

    const handleSubmitQuestion = useCallback(() => {
        if (!selected?.isCorrect) {
            return setError(true)
        }

        handleNextQuestion()
    }, [handleNextQuestion, selected?.isCorrect]);

    useEffect(() => {
        const mainFilter = document.querySelector('main').style.filter;

        if (isOpenQuizModal) {
            document.body.style.overflow = 'hidden';
            if (mainFilter && mainFilter !== 'none') {
                setIsBlindColors(true);
            }
        } else {
            document.body.style.overflow = 'auto';
            setIsBlindColors(false);
        }
        return () => setIsBlindColors(false)
    }, [isOpenQuizModal]);

    const buttonHandler = useMemo(() => {
        if (showScore) {
            return handleSubmit
        }
        return error ? handleNextQuestion : handleSubmitQuestion
    }, [showScore, error, handleNextQuestion, handleSubmit, handleSubmitQuestion])

    return (
        <>
            {isOpenQuizModal
                && <div className={classes.overlay}>
                    <div className={isBlindColors ? `${classes.modal} ${classes.blind}`: classes.modal}>
                        <CloseIcon className={classes.modalClose} onClick={toggleModal} />
                        <div className={classes.modalHeader}>
                            <div className={classes.modalTitle}>Pssssss....</div>
                            <div className={classes.modalDescription}>
                                Take a short quiz and get a <span>25%</span> discount on a tour of your choice
                            </div>
                        </div>
                        <div className={classes.modalBody}>
                            {showScore
                                ?
                                <form onSubmit={handleSubmit} noValidate>
                                    Please provide your email address to receive a promo code:)
                                    <div className={classes.inputWrapper}>
                                        <input
                                            autoComplete='off'
                                            className={errors.email && errors.email ? `${classes.inputField} ${classes.error}` : `${classes.inputField}`}
                                            type='email'
                                            name='email'
                                            onChange={handleChange}
                                            value={values.email || ''}
                                            placeholder='Enter Email'
                                            required
                                        />
                                        <p className={classes.inputError}>
                                            {errors.email && errors.email}
                                        </p>
                                    </div>
                                </form>
                                :
                                <div>
                                    {questionsList[currentQuestion].questionText}
                                    <div>
                                        <ol className={classes.answerList}>
                                            {questionsList[currentQuestion].answerOptions.map((item) => {
                                                return (
                                                    <li
                                                        key={item.answerText}
                                                        className={classes.answerItem}
                                                    >
                                                        {item.answerText}
                                                    </li>
                                                )
                                            })}
                                        </ol>
                                        <div className={classes.answerButtons}>
                                            {questionsList[currentQuestion].answerOptions.map((item) => {
                                                return (
                                                    <button
                                                        key={item.id}
                                                        className={getClassName(item)}
                                                        onClick={handleAnswerClick(item)}
                                                        disabled={error}
                                                    >
                                                        {item.id}
                                                    </button>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={classes.modalFooter}>
                            <div className={classes.modalCount}>Question {currentQuestion + 1}/{questionsList.length}</div>
                            <button
                                type='button'
                                className={`${classes.modalButton} ${!selected ? classes.disabled : ''}`}
                                onClick={buttonHandler}
                                disabled={!selected}
                            >
                                {error ? 'Next' : 'Submit'}
                            </button>
                        </div>
                    </div>
                    <div className={classes.modalBackdrop} onClick={toggleModal} />
                </div>
            }

            {portalPlace && ReactDOM.createPortal(<ModalSuccess isOpenSuccessModal={isOpenSuccessModal} setIsOpenSuccessModal={setIsOpenSuccessModal} />, portalPlace)}
            {portalPlace && ReactDOM.createPortal(<ModalError errorText={errorText} isOpenErrorModal={isOpenErrorModal} setIsOpenErrorModal={setIsOpenErrorModal} />, portalPlace)}
        </>
    );
};

export default ModalQuiz;