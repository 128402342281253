import React from 'react';
import classes from './styles.module.css';
import './../fonts/font.css';
import '@fontsource/lato';
import Header from './Header';
import Footer from './Footer';
import Tourist from '../pages/Tourist';
import { useLocation } from '../hooks/useLocation';
import Guide from '../pages/Guide';

const Layout = () => {
    const { pathname } = useLocation();

    return (
        <main className={pathname === '/guide' ? 'guide' : ''}>
            <Header />
            <div className={classes.main}>
                {pathname === '/guide' ? <Guide /> : <Tourist />}
            </div>
            <Footer />
        </main>
    );
};

export default Layout;
