import React, { useEffect, useRef } from 'react';
import classes from './../styles.module.css'

const Item = ({ item }) => {
    const numberRef = useRef(null)

    useEffect(() => {
        const end = Number(numberRef.current?.dataset.max);
        const onScroll = () => {

            if(numberRef.current && window.scrollY > numberRef.current?.getBoundingClientRect().top - window.innerHeight / 2) {
                window.removeEventListener('scroll', onScroll);
                const interval = setInterval(function() {
                    let start = Number(numberRef.current?.innerHTML || 0);
                    numberRef.current.innerHTML = ++start;
                    if(start === end) {
                        clearInterval(interval);
                    }
                }, item.interval);
            }
        }
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    },[item.interval])

    return (
        <span className={classes.joinUsItemNumber} ref={numberRef} data-max={item.number}>0</span>
    );
};

export default Item;