/* Name and Email */
export function validate(values) {
    let errors = {};
    if (!values.name) {
        errors.name = 'Name is required';
    } else if (values.name.length < 5) {
        errors.name = 'Name must be 5 or more characters';
    }
    if (!values.email) {
        errors.email = 'Email address is required';
    } else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/.test(values.email)) {
        errors.email = 'Email address is invalid';
    }
    return errors;
}

/* Email only */
export function validateEmail(values) {
    let errors = {};
    if (!values.email) {
        errors.email = 'Email address is required';
    } else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/.test(values.email)) {
        errors.email = 'Email address is invalid';
    }
    return errors;
}