import React, { useEffect, useState } from 'react';
import classes from '../styles.module.css';
import { ReactComponent as CloseIcon } from '../../../images/close.svg';
import useForm from '../../../hooks/useForm';
import { validate, validateEmail } from '../../FormComponents/formValidationRules';
import { useMutation } from '@apollo/client';
import { subscribeMutationGql } from '../../../graphql/mutations.gql';
import ModalSuccess from '../ModalSuccess';
import ModalError from '../ModalError';
import { useLocation } from '../../../hooks/useLocation';
import ReactDOM from 'react-dom';

const ModalSocialLink = ({ isOpenModalSocialLink, setIsOpenModalSocialLink }) => {
    const [subscribeFunction] = useMutation(subscribeMutationGql);
    const [guideFormFunction] = useMutation(subscribeMutationGql);
    const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);
    const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
    const [errorText, setErrorText] = useState('');
    const portalPlace = document.getElementById('portal');

    const toggleModal = () => {
        setIsOpenModalSocialLink(false)
    };

    const [isBlindColors, setIsBlindColors] = useState(false);
    const { pathname } = useLocation();

    const handleSubmitChange = pathname === '/guide' ? handleGuideForm : handleSubscribe;
    const validateFunc = pathname === '/guide' ? validate : validateEmail;

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        handleReset,
    } = useForm(handleSubmitChange, validateFunc);

    function handleSubscribe() {
        subscribeFunction({
            variables: {
                type: 'Tourist',
                email: values.email,
            }
        }).then(response => {
            if (response?.data?.subscribe?.id) {
                toggleModal()
                setIsOpenSuccessModal(true)
                handleReset()
            }
            if (response?.errors?.[0]?.message) {
                toggleModal()
                setErrorText(response?.errors?.[0]?.message)
                setIsOpenErrorModal(true)
            }
        }).catch((error) => {
            if (error?.message) {
                setErrorText(error?.message)
                setIsOpenErrorModal(true)
            }
        })
    }

    function handleGuideForm() {
        guideFormFunction({
            variables: {
                type: 'Guide',
                fullName: values.name,
                email: values.email,
            }
        }).then(response => {
            if (response?.data?.subscribe?.id) {
                toggleModal()
                setIsOpenSuccessModal(true)
                handleReset()
            }
            if (response?.errors?.[0]?.message) {
                toggleModal()
                setErrorText(response?.errors?.[0]?.message)
                setIsOpenErrorModal(true)
            }
        }).catch((error) => {
            if (error?.message) {
                setErrorText(error?.message)
                setIsOpenErrorModal(true)
            }
        })
    }

    useEffect(() => {
        const mainFilter = document.querySelector('main').style.filter;

        if (isOpenModalSocialLink) {
            document.body.style.overflow = 'hidden';
            if (mainFilter && mainFilter !== 'none') {
                setIsBlindColors(true);
            }
        } else {
            document.body.style.overflow = 'auto';
            setIsBlindColors(false);
        }
        return () => setIsBlindColors(false)
    }, [isOpenModalSocialLink]);

    return (
        <>
            {isOpenModalSocialLink
                && <div className={classes.overlay}>
                    <div className={isBlindColors ? `${classes.modal} ${classes.blind}`: classes.modal}>
                        <CloseIcon className={classes.modalClose} onClick={toggleModal} />
                        <div className={classes.modalHeader}>
                            <div className={classes.modalDescription}>
                                Umeguide App will be available in the nearest future.
                                Please leave your Email address, so that we can notify you about the launch
                            </div>
                        </div>
                        <div className={classes.modalBody}>
                            {pathname === '/guide'
                                ?
                                <form onSubmit={handleSubmit} noValidate>
                                    Please provide your name and email address to receive a promo code:)
                                    <div className={`${classes.inputWrapper} ${classes.first}`}>
                                        <input
                                            autoComplete='off'
                                            className={errors.name && errors.name ? `${classes.inputField} ${classes.error}` : `${classes.inputField}`}
                                            type='text'
                                            name='name'
                                            onChange={handleChange}
                                            value={values.name || ''}
                                            placeholder='Full Name'
                                            required
                                        />
                                        <p className={classes.inputError}>
                                            {errors.name && errors.name}
                                        </p>
                                    </div>
                                    <div className={classes.inputWrapper}>
                                        <input
                                            autoComplete='off'
                                            className={errors.email && errors.email ? `${classes.inputField} ${classes.error}` : `${classes.inputField}`}
                                            type='email'
                                            name='email'
                                            onChange={handleChange}
                                            value={values.email || ''}
                                            placeholder='Email'
                                            required
                                        />
                                        <p className={classes.inputError}>
                                            {errors.email && errors.email}
                                        </p>
                                    </div>
                                </form>
                                :
                                <form onSubmit={handleSubmit} noValidate>
                                    Please provide your email address to receive a promo code:)
                                    <div className={classes.inputWrapper}>
                                        <input
                                            autoComplete='off'
                                            className={errors.email && errors.email ? `${classes.inputField} ${classes.error}` : `${classes.inputField}`}
                                            type='email'
                                            name='email'
                                            onChange={handleChange}
                                            value={values.email || ''}
                                            placeholder='Enter Email'
                                            required
                                        />
                                        <p className={classes.inputError}>
                                            {errors.email && errors.email}
                                        </p>
                                    </div>
                                </form>
                            }
                        </div>
                        <div className={classes.modalFooter}>
                            <button
                                type='button'
                                onClick={handleSubmit}
                                className={classes.modalButton}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                    <div className={classes.modalBackdrop} onClick={toggleModal} />
                </div>
            }

            {portalPlace && ReactDOM.createPortal(<ModalSuccess isOpenSuccessModal={isOpenSuccessModal} setIsOpenSuccessModal={setIsOpenSuccessModal} />, portalPlace)}
            {portalPlace && ReactDOM.createPortal(<ModalError errorText={errorText} isOpenErrorModal={isOpenErrorModal} setIsOpenErrorModal={setIsOpenErrorModal} />, portalPlace)}
        </>
    );
};

export default ModalSocialLink;