import React, { useState } from 'react';
import classes from './styles.module.css'
import Container from '../../Container';
import subscribeImage from '../../../images/subscribe_bg.png';
import useForm from '../../../hooks/useForm';
import { validateEmail } from '../formValidationRules';
import { useMutation } from '@apollo/client';
import { subscribeMutationGql } from '../../../graphql/mutations.gql';
import ModalSuccess from '../../Modals/ModalSuccess';
import ModalError from '../../Modals/ModalError';

const SubscribeForm = ({ id }) => {
    const [subscribeFunction] = useMutation(subscribeMutationGql);
    const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);
    const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
    const [errorText, setErrorText] = useState('');

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        handleReset,
    } = useForm(handleSubscribe, validateEmail);

    function handleSubscribe() {
        subscribeFunction({
            variables: {
                type: 'Tourist',
                email: values.email,
            }
        }).then(response => {
            if (response?.data?.subscribe?.id) {
                setIsOpenSuccessModal(true)
                handleReset()
            }
            if (response?.errors?.[0]?.message) {
                setErrorText(response?.errors?.[0]?.message)
                setIsOpenErrorModal(true)
            }
        }).catch((error) => {
            if (error?.message) {
                setErrorText(error?.message)
                setIsOpenErrorModal(true)
            }
        })
    }

    return (
        <>
            <div id={id} className={classes.subscribe}>
                <Container>
                    <div className={classes.subscribeContainer}>
                        <div className={classes.subscribeLeft}>
                            <div className={classes.preTitle}>Hey there!</div>
                            <div className={classes.subscribeTitle}>Subscribe and receive a discount</div>
                            <div className={classes.subscribeText}>
                                You will receive not only a 10% discount for any tour but also
                                news about our fresh and fantastical tours
                            </div>
                            <form onSubmit={handleSubmit} noValidate>
                                <div className={classes.inputWrapper}>
                                    <input
                                        autoComplete='off'
                                        className={errors.email && errors.email ? `${classes.inputField} ${classes.error}` : `${classes.inputField}`}
                                        type='email'
                                        name='email'
                                        onChange={handleChange}
                                        value={values.email || ''}
                                        placeholder='Email'
                                        required
                                    />
                                    <p className={classes.inputError}>
                                        {errors.email && errors.email}
                                    </p>
                                    <button type='submit' className={classes.subscribeButton}>Subscribe</button>
                                </div>
                            </form>
                        </div>
                        <div className={classes.subscribeRight}>
                            <img className={classes.subscribeImage} src={subscribeImage} alt='Subscribe' />
                        </div>
                    </div>
                </Container>
            </div>

            <ModalSuccess isOpenSuccessModal={isOpenSuccessModal} setIsOpenSuccessModal={setIsOpenSuccessModal} />
            <ModalError errorText={errorText} isOpenErrorModal={isOpenErrorModal} setIsOpenErrorModal={setIsOpenErrorModal} />
        </>
    );
};

export default SubscribeForm;