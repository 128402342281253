import React, { useState } from 'react';
import classes from './../styles.module.css';
import { ReactComponent as PlusIcon } from '../../../images/plus.svg';
import { ReactComponent as MinusIcon } from '../../../images/minus.svg';

const FAQItem = ({ title, description }) => {
    const [state, setState] = useState(true);

    const handleClick = () => {
        setState(prev => !prev);
    };

    return (
        <div className={classes.faqItem}>
            <div className={classes.faqItemPreview} onClick={handleClick}>
                <div className={state ? `${classes.faqItemToggle}` : `${classes.faqItemToggle} ${classes.open}`}>
                    {state ? <PlusIcon className={classes.faqItemToggleIcon} /> : <MinusIcon className={classes.faqItemToggleIcon} />}
                </div>
                {title}
            </div>
            <div className={state ? null : `${classes.faqItemDescriptionOpen}`}>{!state ? description : null}</div>
        </div>
    );
};

export default FAQItem;