import React from 'react';
import classes from './styles.module.css'
import Container from '../Container';
import FAQItem from './FAQItem';
import { faqList } from './faqList';

const FAQ = () => {
    return (
        <div className={classes.faq}>
            <Container>
                <div className={classes.faqContainer}>
                    <h2>FAQ</h2>
                    <div className={classes.faqList}>
                        {faqList.map((item) => {
                            return (
                                <FAQItem key={item.title} {...item} />
                            );
                        })}
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default FAQ;