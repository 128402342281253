import React, { useEffect, useState } from 'react';
import classes from './styles.module.css';
import { ReactComponent as EyeIcon } from '../../images/eye.svg';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useLocation } from '../../hooks/useLocation';

const HeaderButtons = () => {
    const [openTheme, setOpenTheme] = useState(false);
    const { pathname } = useLocation();

    const toggleTheme = () => {
        setOpenTheme(!openTheme)
    }

    useEffect(() => {
        if (openTheme) {
            document.querySelector('main').style.filter = 'grayscale(90%)';
        } else {
            document.querySelector('main').style.filter = 'none';
        }
    }, [openTheme]);

    return (
        <div className={classes.headerButtons}>
            <button className={classes.buttonSwitch} onClick={toggleTheme}><EyeIcon /></button>
            <AnchorLink
                href={pathname === '/guide' ? '#guide-form' : '#subscribe'}
                type='button'
                className={classes.buttonInterested}
            >
                I’m interested
            </AnchorLink>
        </div>
    );
};

export default HeaderButtons;