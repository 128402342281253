import React, { useState } from 'react';
import classes from './styles.module.css';
import { getTrackBackground, Range } from 'react-range';

const COLORS = ['rgba(34,149,239,1)','#F4F5FB'];

const CustomRange = ({ label, step, min, max, value, setValue, price }) => {
    const [state, setState] = useState(value || { values: [20] });
    const _state = typeof value !== 'undefined' ? value : state;
    const _setState = setValue || setState;

    return (
        <div className={classes.rangeContainer}>
            <label className={classes.label}>{label}</label>
            <div className={classes.tooltipMobile}>
                <div className={classes.tooltipInner}>
                    {price
                        ?
                        <span className={classes.tooltipPrice}>$</span>
                        : ''
                    }
                    <input
                        type='number'
                        className={price ? `${classes.tooltipInput} ${classes.price}` : classes.tooltipInput}
                        value={_state.values[0]}
                        onChange={(event) => {
                            event.target.value = event.target.value.replace(/^-/, '');
                            _setState({values: [event.target.value]})
                        }}
                    />
                </div>
            </div>
            <div className={classes.rangeBox}>
                <Range
                    values={_state.values}
                    step={step}
                    min={min}
                    max={max}
                    price={price}
                    onChange={(values) => {
                        _setState({values})
                    }}
                    renderTrack={({ props, children }) => (
                        <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            className={classes.track}
                            style={{
                                ...props.style,
                            }}
                        >
                            <div className={classes.start} />
                            <div
                                ref={props.ref}
                                style={{
                                    height: '5px',
                                    width: '100%',
                                    borderRadius: '4px',
                                    background: getTrackBackground({
                                        values: _state.values,
                                        colors: COLORS,
                                        min: min,
                                        max: max
                                    }),
                                    alignSelf: 'center'
                                }}
                            >
                                {children}
                            </div>
                            <div className={classes.end} />
                        </div>
                    )}
                    renderThumb={({ props }) => (
                        <div
                            {...props}
                            className={classes.thumb}
                            style={{
                                ...props.style,
                            }}
                        />
                    )}
                />
            </div>
            <div className={classes.tooltip}>
                <div className={classes.tooltipInner}>
                    {price
                        ?
                        <span className={classes.tooltipPrice}>$</span>
                        : ''
                    }
                    <input
                        type='number'
                        className={price ? `${classes.tooltipInput} ${classes.price}` : classes.tooltipInput}
                        value={_state.values[0]}
                        onChange={(event) => {
                            event.target.value = event.target.value.replace(/^-/, '');
                            _setState({values: [event.target.value]})
                        }}
                    />
                </div>
            </div>
            <label className={classes.labelIsMobile}>{label}</label>
        </div>
    );
};

export default CustomRange;
