import React, {useRef} from 'react';
import classes from './styles.module.css'
import Container from '../Container';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { reviewList } from './reviewList';

const WhatTheySay = ({ id }) => {

    const ref = useRef(null)

    const settings = {
        dots: true,
        infinite: true,
        rows: 1,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 700,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        pauseOnDotsHover: false,
        appendDots: dots => (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                    width: '100%',
                    marginTop: '24px',
                }}
            >
                {dots}
            </div>
        ),
        customPaging: i => (
            <div
                style={{
                    width: '45px',
                    height: '4px',
                    marginRight: '30px',
                    textIndent: '-9999999px',
                    backgroundColor: 'var(--secondary-magnolia)',
                    border: 'none',
                    borderRadius: '6px',
                    cursor: 'pointer'
                }}
            >
                {i + 1}
            </div>
        ),
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    }

    const handleSliderPause = () => {
        if (ref.current) {
            ref.current.slickPause()
        }
    }

    return (
        <div id={id} className={classes.whatTheySay}>
            <Container direction='column'>
                <div className={classes.preTitle}>What travellers say</div>
                <h2>“Excellent app, guys”</h2>
                <div className={classes.subTitle}>
                    Mother of our CTO
                </div>
                <Slider ref={ref} className={classes.reviewList} {...settings}>
                    {reviewList.map((item) => {
                        return (
                            <div className={classes.reviewItem} key={item.title} onClick={handleSliderPause}>
                                <div className={classes.reviewTop}>
                                    <div className={classes.reviewTitle}>
                                        {item.title}
                                    </div>
                                    <div className={classes.reviewRate}>
                                        <img src={item.rate} alt={item.title} />
                                    </div>
                                </div>
                                <div className={classes.reviewContent}>
                                    {item.text}
                                </div>
                                <div className={classes.reviewAuthor}>
                                    <img className={classes.reviewAuthorImage} src={item.authorImage} alt={item.authorName} />
                                    <div className={classes.authorInfo}>
                                        <div className={classes.reviewAuthorName}>{item.authorName}</div>
                                        <div className={classes.reviewAuthorPosition}>{item.authorPosition}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
            </Container>
        </div>
    );
};

export default WhatTheySay;