import startsIcon from '../../images/stars.svg';
import authorImage from '../../images/author.svg';

export const reviewList = [
  {
    title: 'Comfortable, flexible, start with the guided tour whenever you want! :)',
    rate: startsIcon,
    text: 'When visiting San Marino on Sunday there was no tour guide available, so we decided to try out the Umeguide app - I must say, ' +
        'it was totally worth it! The app is intuitive to use and you can start and pause the tour whenever you want like i.e. for a lunch or a coffee break, ' +
        'which makes the trip super comfortable compared with the usual guided tours. We recommend it!',
    authorImage: authorImage,
    authorName: 'Pinky',
    authorPosition: 'Chiwawa, sock thief',
  },
  {
    title: 'The right choice for self-guided tours!',
    rate: startsIcon,
    text: 'Probably the usual problem in Florence, almost all guided tours booked weeks in advance… luckily, friends recommended this app and I must say, we enjoyed it a lot. ' +
        'You just follow the instructions and go from point to point while listening to the audio guide and do quizzes. Since we went there in summer, we took a lot of ' +
        'breaks which was really easygoing with the app.',
    authorImage: authorImage,
    authorName: 'Pinky',
    authorPosition: 'Chiwawa, sock thief',
  },
  {
    title: 'Make the most of some hours of spare time',
    rate: startsIcon,
    text: 'I had little time while visiting Munich on a business trip, but still wanted to get to know the city at least a bit. Umeguide suggested a tour closely ' +
        'according to my preferences (I had like 2 hours and just wanted to take a walk without public transportation etc.) and it was great! Amazingly simple to use, ' +
        'high quality of the photos and audio recordings, great tips for a quick coffee or ice cream and at the same time I learnt something about the city. ' +
        'This app is really handy and i can certainly recommend it.',
    authorImage: authorImage,
    authorName: 'Pinky',
    authorPosition: 'Chiwawa, sock thief',
  },

  {
    title: 'Guided tour with a toddler! :)',
    rate: startsIcon,
    text: 'Our son is only 1 year old, which is why it is impossible for us to take part in guided tours during our holidays. however, since my husband and me miss ' +
        'exploring new places we bought a family-friendly tour on Umeguide and we were completely excited about it! We started the tour when our little one was about ' +
        'to take a nap, made a break when he woke up and wanted to go to the beach and continued the tour the next day during his nap again. Isn\'t that amazing? :) ' +
        'This was just a perfect solution for us! For parents 100% recommended! :-D',
    authorImage: authorImage,
    authorName: 'Pinky',
    authorPosition: 'Chiwawa, sock thief',
  },

  {
    title: 'Cool italian coffee tour',
    rate: startsIcon,
    text: 'Me and my girlfriend wanted to try something new when we visited Italy, not only the typical boring tourist stuff, which is the reason we downloaded a ' +
        'tour from Umeguide, which was a coffee orientated tour. It guided us through different coffee shops, explaining the origin, differences in production and ' +
        'aroma of various types of coffee, which was super interesting. I haven\'t seen any comparable app like this before. The tours are very affordable and the ' +
        'app super easy to use. Having learned about the tour-creation feature, we are even thinking about creating a tour in our city ourselves.',
    authorImage: authorImage,
    authorName: 'Pinky',
    authorPosition: 'Chiwawa, sock thief',
  },
];